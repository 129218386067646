import { css } from '@emotion/react'
import { isLink } from 'datocms-structured-text-utils'
import { graphql } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'
import { renderNodeRule } from 'react-datocms/structured-text'

import { DatoStructuredText } from '@/components/DatoStructuredText'
import { GatsbyImageFocused } from '@/components/GatsbyImageFocused'
import { aspectRatio, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { AllRecipients } from './AllRecipients'
import { RecentRecipients } from './RecentRecipients'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: Queries.AwardSectionFragment | null
  bgColor: 'WHITE' | 'GRAY'
}

export const AwardSection = ({
  data,
  bgColor,
  ...props
}: Props): JSX.Element => {
  const styles = {
    section: css`
      position: relative;
      display: grid;
      grid-row-gap: var(--row-54);
      padding: var(--row-72) 0 var(--row-108);
      background: ${bgColor === 'WHITE'
        ? '#fff'
        : bgColor === 'GRAY' && colors.gray20};
      ${bgColor === 'WHITE' &&
      css`
        background: #fff;
      `}
      ${bgColor === 'GRAY' &&
      css`
        background: ${colors.gray20};
        color: #fff;
      `}
    `,
    intro: css`
      position: relative;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: var(--margin);
      padding: 0 var(--margin);
      min-height: calc((100vw - 3 * var(--margin)) / 2);
      ${mq().s} {
        grid-template-columns: 1fr;
      }
    `,
    image: css`
      position: absolute;
      grid-column: 1 / 2;
      grid-row: 1 / 2;
      width: 100%;
      height: 100%;
      ${mq().s} {
        position: relative;
        grid-column: auto;
        grid-row: auto;
        ${aspectRatio(1.5)}
      }
    `,
    text: css`
      grid-column: 2 / -1;
      align-self: center;
      ${mq().s} {
        grid-column: auto;
      }
    `,
    heading: css`
      font-size: var(--fs-60);
      margin: 0 0 0.5em;
      ${mq().s} {
        margin-top: 0.5em;
      }
      &:after {
        content: '';
        display: block;
        width: 1em;
        height: 2px;
        background: ${colors.red};
        margin: 0.5em 0;
      }
      ${bgColor === 'WHITE' &&
      css`
        color: ${colors.gray10};
      `}
      ${bgColor === 'GRAY' &&
      css`
        color: #fff;
      `}
    `,
    body: css`
      a {
        color: ${bgColor === 'WHITE'
          ? colors.red
          : bgColor === 'GRAY' && colors.redLight};
        @media (hover: hover) {
          &:hover {
            color: ${bgColor === 'WHITE'
              ? colors.redDark
              : bgColor === 'GRAY' && '#fff'};
          }
        }
      }
    `,
  }
  return (
    <section
      css={styles.section}
      {...props}
    >
      <section css={styles.intro}>
        <GatsbyImageFocused
          css={styles.image}
          data={data?.image}
          image={data?.image?.gatsbyImageData}
        />
        <div css={styles.text}>
          <h2 css={styles.heading}>{data?.heading}</h2>
          <div css={styles.body}>
            <DatoStructuredText
              data={data?.body}
              customNodeRules={[
                renderNodeRule(isLink, ({ node, children, key }) => (
                  <a
                    key={key}
                    href={node.url}
                    target="_blank"
                    rel="noreferrer"
                    {...node.meta}
                  >
                    {children}
                  </a>
                )),
              ]}
            />
          </div>
        </div>
      </section>
      {(data?.recentRecipients?.length || 0) > 0 && (
        <RecentRecipients
          data={data?.recentRecipients}
          thumbnailStyle={data?.thumbnailStyle}
          bgColor={bgColor}
        />
      )}
      {(data?.allRecipients?.length || 0) > 0 && (
        <AllRecipients
          data={data?.allRecipients}
          bgColor={bgColor}
        />
      )}
    </section>
  )
}

export const AwardSectionFragment = graphql`
  fragment AwardSection on DatoCmsAwardSection {
    __typename
    id: originalId
    image {
      gatsbyImageData(
        width: 960
        sizes: "(max-width: 540px) 100vw, 50vw"
        imgixParams: { ar: "1:1", fit: "crop", crop: "focalpoint" }
      )
      ...ImageFocalData
    }
    heading
    body {
      value
    }
    recentRecipients {
      ...AwardRecipientRecent
    }
    allRecipients {
      ... on DatoCmsAwardRecipientAll {
        ...AwardRecipientAll
      }
      ... on DatoCmsAwardSpecialHeading {
        ...AwardSpecialHeading
      }
    }
    thumbnailStyle
  }
`
